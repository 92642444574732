import { mapGetters } from "vuex"
import {
  HIGHLIGHTED_ISSUES_WIDGET,
  WIDGET_CLASS,
  QUERY_MAP,
  MESSAGE_VIEW_WIDGETS,
  ISSUE_VIEW_DUE_DATE_WIDGETS,
  ISSUE_VIEW_UPDATED_AT_WIDGETS,
  ISSUE_TYPES
} from "@/constants"
import moment from "moment"

export default {
  name: "Dashboard",
  data() {
    return {
      issueSkeletonLoaderNumber: 5
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser              : "auth/loggedInUser",
      loggedInUserPolicies      : "accessControl/loggedInUserPolicies",
      issuesWithUnreadMessages  : "kpis/issuesWithUnreadMessages",
      issuesWithUnansweredReport: "kpis/issuesWithUnansweredReport",
      issueWithDueDate          : "kpis/issueWithDueDate",
      issuesWhichAreOverdue     : "kpis/issuesWhichAreOverdue",
      issuesWhichAreInactive    : "kpis/issuesWhichAreInactive",
      isLoadingKpis             : "kpis/isLoadingKpis",
      loggedInUserRoleTypes     : "auth/loggedInUserRoleTypes",
      issues                    : "issues/issues",
      isLoadingIssues           : "issues/isLoadingIssues"
    }),
    hasIssueAccessForDueDateProperty() {
      return this.loggedInUserPolicies["Issue view"]?.some(policy => policy.select.includes("dueDate"))
    },
    hasIssueAccessForUpdatedAtProperty() {
      return this.loggedInUserPolicies["Issue view"]?.some(policy => policy.select.includes("updatedAt"))
    },
    hasIssueAccessForAssigneeProperty() {
      return this.loggedInUserPolicies["Issue view"]?.some(policy => policy.select.includes("assigneeId"))
    },
    loggedInUserName(){
      return this.loggedInUser.name
    },
    issuesWithUnreadMessagesCount() {
      return this.issuesWithUnreadMessages.length
    },
    issuesWithUnansweredReportCount() {
      return this.issuesWithUnansweredReport.length
    },
    issuesWhichAreInactiveCount(){
      return this.issuesWhichAreInactive.length
    },
    issueWithDueDateCount() {
      return this.issueWithDueDate.length
    },
    issuesWhichAreOverdueCount() {
      return this.issuesWhichAreOverdue.length
    },
    kpiValues() {
      return {
        ISSUE_DUE_SOON           : this.issueWithDueDateCount,
        ISSUE_UNREAD_MESSAGES    : this.issuesWithUnreadMessagesCount,
        ISSUE_UNANSWERED_MESSAGES: this.issuesWithUnansweredReportCount,
        ISSUE_OVERDUE            : this.issuesWhichAreOverdueCount,
        ISSUE_INACTIVE           : this.issuesWhichAreInactiveCount
      }
    },
    sortedIssues() {
      return [...this.issues].sort((firstIssue, secondIssue) => {
        // Primary sort: dueDate (earliest to latest), nulls go to the bottom
        if (firstIssue.dueDate === null && secondIssue.dueDate !== null) {
          return 1
        }
        if (secondIssue.dueDate === null && firstIssue.dueDate !== null) {
          return -1
        }
        if (firstIssue.dueDate !== null && secondIssue.dueDate !== null) {
          const dueDateDifference = new Date(firstIssue.dueDate) - new Date(secondIssue.dueDate)
          if (dueDateDifference !== 0) {
            return dueDateDifference
          }
        }

        // Secondary sort: receivedAt (newest to oldest, if present)
        const firstIssueReceivedAt  = firstIssue.receivedAt ? new Date(firstIssue.receivedAt) : null
        const secondIssueReceivedAt = secondIssue.receivedAt ? new Date(secondIssue.receivedAt) : null

        if (firstIssueReceivedAt && secondIssueReceivedAt) {
          const receivedAtDifference = secondIssueReceivedAt - firstIssueReceivedAt
          if (receivedAtDifference !== 0) {
            return receivedAtDifference
          }
        } else if (firstIssueReceivedAt) {
          return -1
        } else if (secondIssueReceivedAt) {
          return 1
        }

        // Tertiary sort: createdAt (newest to oldest)
        return new Date(secondIssue.createdAt) - new Date(firstIssue.createdAt)
      })
    },
    kpisToDisplay() {
      return Object.keys(this.widgetsToDisplay)
        .map(key => ({
          key,
          label   : this.$t(this.widgetsToDisplay[key].label),
          value   : this.kpiValues[key],
          disabled: !this.kpiValues[key]
        }))
    },
    hasAccessToMessageView() {
      return !!this.loggedInUserPolicies["Message view"]
    },
    widgetsToDisplay() {
      const widgetsToDisplay = {}
      for (const key in HIGHLIGHTED_ISSUES_WIDGET) {
        if (this.hasAccessToMessageView) {
          if (MESSAGE_VIEW_WIDGETS.includes(key)) {
            widgetsToDisplay[key] = HIGHLIGHTED_ISSUES_WIDGET[key]
          }
        }
        if (this.hasIssueAccessForDueDateProperty) {
          if (ISSUE_VIEW_DUE_DATE_WIDGETS.includes(key)) {
            widgetsToDisplay[key] = HIGHLIGHTED_ISSUES_WIDGET[key]
          }
        }
        if (this.hasIssueAccessForUpdatedAtProperty) {
          if (ISSUE_VIEW_UPDATED_AT_WIDGETS.includes(key)) {
            widgetsToDisplay[key] = HIGHLIGHTED_ISSUES_WIDGET[key]
          }
        }
      }
      return widgetsToDisplay
    },
    maxValueInDashBoardWidget() {
      return this.kpisToDisplay.length ? Math.max(...this.kpisToDisplay.map(kpi => kpi.value)) : 0
    },
    numberOfDigitsInMaxValue() {
      return this.maxValueInDashBoardWidget.toString().length
    },
    hasAccessToHighlightsWidget() {
      return !!this.loggedInUserPolicies["Message view"]
    },
    assignedIssuesToDisplay() {
      const assignedIssuesToDisplay = this.sortedIssues.slice(0, 8)
      return assignedIssuesToDisplay.map(issue => {
        const type = issue.typeId === ISSUE_TYPES[0].id ? this.$t("1866") :
          issue.typeId === ISSUE_TYPES[1].id ? this.$t("1867") : ""
        return {
          id     : issue.id,
          typeId : issue.typeId,
          summary: issue.summary && issue.summary.length ? issue.summary : this.$t("1854", { type }),
          dueDate: issue.dueDate,
          dueSoon: issue.dueDate && new Date(issue.dueDate) < new Date(new Date().setDate(new Date().getDate() + 5))
            && new Date(issue.dueDate) > new Date(),
          overdue: issue.dueDate && new Date(issue.dueDate) < new Date()
        }
      })
    }
  },
  methods: {
    getItemClass(hover, spanClass, disabled, text) {
      return spanClass ? { [WIDGET_CLASS.HOVER_LABEL]: hover, "grey--text text--darken-4": !disabled && text, "grey--text": disabled  } : { [WIDGET_CLASS.HOVER_ICON]: hover }
    },
    getColorClass(item) {
      return item.disabled ? WIDGET_CLASS.INDICATOR_DISABLED : WIDGET_CLASS.INDICATOR_ACTIVE
    },
    handleKpiItemClick(item) {
      if (!item.disabled) {
        const queryToPush = QUERY_MAP[item.key] || {}
        this.$router.push({ name: "issues", query: queryToPush })
      }
    },
    handleAssignedIssueClick(item) {
      this.$router.push({ name: "issue", params: { id: item.id } })
    },
    getColorForChip(item, text) {
      if (item.overdue) {
        if (text) {
          return "error darken-2"
        }
        return "red lighten-5"
      }
      if (item.dueSoon) {
        if (text) {
          return "orange darken-4"
        }
        return "orange lighten-5"
      }
      if (item.dueDate) {
        if (text) {
          return "grey darken-4"
        }
        return "grey lighten-4"
      }
      if (text) {
        return "grey"
      }
      return "grey lighten-5"
    },
    getClassForDueDateSpan(item) {
      if (item.overdue) {
        return "error--text text--darken-2"
      }
      if (item.dueSoon) {
        return "orange--text text--darken-4"
      }
      if (item.dueDate) {
        return "grey--text text--darken-4"
      }
      return "grey--text"
    },
    getDueDateChipValue(item) {
      if (item.dueDate) {
        const dueDate = moment(item.dueDate).format("MMM DD")
        return this.$t("1865", { dueDate })
      }
      return this.$t("1864")
    }
  }
}